import React from 'react';

import ExpertiseContainer, { EXPERTISE, generateBreadcrumb } from './container';

const Mediation = () => {
  return (
    <ExpertiseContainer currentOption={EXPERTISE.MEDIATION}>
      <h1>Mediation</h1>

      {generateBreadcrumb('mediation')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="mediation"
          src="/img/competences_sprite.jpg"
          alt="Mediation"
        />
      </div>

      <p>
        Family mediation can help you negotiate a{' '}
        <span className="accent">settlement</span> in respect with the family
        members' interests and reduce your legal costs through a fixed number of
        sessions subsidized by the Government of Quebec.
      </p>
      <p>
        The consultation of an{' '}
        <span className="accent2">independent and qualified mediator</span>{' '}
        helps the parties to negotiate, with equal force, the establishment of a
        post-separation agreement respecting the distinct needs of each.
      </p>
      <p>
        The parties already separated or divorced who want to make a change to
        their agreement or judgment can also do so through{' '}
        <span className="accent2">negotiations</span> in family mediation
        sessions.
      </p>
    </ExpertiseContainer>
  );
};

Mediation.propTypes = {};

export default Mediation;
