import React from 'react';

import { PAGES } from '../../../utils/utils';
import Mediation from '../../../components/en/expertise/mediation';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

const MediationPage = () => (
  <Layout lang="en" page={PAGES.COMPETENCES_MEDIATION}>
    <SEO title="Mediation" lang="en" />
    <Mediation />
  </Layout>
);

export default MediationPage;
